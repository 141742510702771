.minicard-book { 
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 0.7rem;
  margin:5px;
  cursor: pointer;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
}

.minicard-book img{ 
  border-radius: 10px;
  min-height:64px;
  max-height: 64px;
  min-width: 64px;
  max-width: 64px;
  overflow: hidden;
  object-fit: cover;  
}

.minicard-book .ladoa{
  display: flex;
  justify-content:flex-start;
  align-items:center;
}

.title-minicard-book{
  font-size:19px;
  font-weight:bold;
  text-align: center;
  width: 100%;
  display: flex;
  align-items:flex-start;
  text-align:left;
  margin-left: 10px;
  margin-top: 10px;
  line-height: 1;
}
.title-minicard-book span{
  padding-top: 5px;
  font-size:12px;
}
.historico{
  display: flex;
  align-items: flex-start;
  margin-top:10px;
  width:100%
}
.btn-acao-card{
  width: 100%;
  display: flex;
}
.book button {
  width: 100%;
  margin:5px
}
.book butto:hover {
  background-color: #348834;
}
