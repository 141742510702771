body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fntrbt{
  font-family: 'Roboto', sans-serif;
}

.info, .quem-somos{
  margin-top:30px
}
.info{
  margin-top:50px;
}
.nome{
  padding-top:2rem
}

.foto_autor, .logo{
  text-align:center;
}

.logo img{
  width:300px;
  max-width: 100%;
}

.bg-drop {
  margin-left: 5px;
}
.d-flex {
  display: flex;
}
.d-flex .Input{
  width: 13rem;
  background-color: #333
}


.header{
  top: 0;
  position:sticky;
}




@media (min-width: 900px) {
  .nav_bar_items {
    width: 90%;
    display: flex !important;
    justify-content: flex-end !important;
    flex-direction: row !important;
  }

}
@media(max-width: 730px) {
  .nav_bar_items {
    margin-top: 10px;
    border-top: 1px solid #999;
  }
  .navItem {
    border-bottom: 1px solid #999;
    margin-bottom: 0px;
    width: 100%;
  }
  .itemmobile{
    display: none !important;
  }
  .linhaheader{
    border-bottom: 1px solid #999;
    margin-bottom:10px;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
  .linhaheader img{
    padding-bottom: 10px;
  }

}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  width: 100%;
  margin-bottom: 5px!important;
  padding: 12px 16px;
  border-radius: 4px;
  border: 2px solid #ddd;
  font-size: 15px;
  color: #444;
  transition: border-color 0.2s;
}

input:focus {
  border-color: #111;
  border: 3px solid #ddd;
}

.btn-login{
  background-color: #080!important;
}

.home-page{
  margin-top:20px;
}

.categories{
  display:flex;
  justify-content:space-around;
  margin-bottom: 30px;
}

.categories button{
  background-color: #fff;
  border-radius:5px;
  border-color: #080!important;
  padding:10px 15px;
}

.categories button:hover{
  background-color: rgb(205, 205, 205);
}


.contentBooks{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
  align-content: space-between;
}

.loading{
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
  vertical-align:middle;
  width:100%;
  height:50vh;
}

.book { 
  max-width: 300px;
  min-width: 300px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 0.1rem solid #383838;
  border-radius: 10px;
  margin:5px;  
}

.book-img img{
  max-width:100%;
}

.title-book {
  font-size:16px;
  font-weight:bold;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  min-height:30px;
}

.box-img-book{
  min-height:300px;
  display: flex;
  justify-content:center;
  align-items:center;

}

.historico{
  display: flex;
  align-items: flex-start;
  margin-top:10px;
  width:100%
}

.btn-acao-card{
  width: 100%;
  display: flex;
}

.book button {
  width: 100%;
  margin:5px;
  font-size:10px;
}

.book button:hover {
  background-color: #348834;
}

.book-img{
  display: flex;
  justify-content: center;
  align-items:center;
  margin-bottom: 40px;
}

.img-book{
  max-width: 100%;
  max-height: 300px;
  cursor: pointer;
}

.price-book{
  font-weight: bold;
  display: flex;
  align-items:flex-start;
  width: 100%;
  margin-top: 20px;
  margin-left: 10px;
}

.book-page{
  margin-top:30px;
}
.title-book-page{
  margin-top:30px;
}

.btn-acao{
  display:flex;
  justify-content: center;
  align-items:space-around;
}
.btn{
  width:100%;
  margin-right:5px;
  margin-bottom: 5px;
}

.btn-add{
  width:50px;
  background-color:#080!important;
  border-color:#080!important;
}
.btn-remove{
  background-color:rgb(239, 50, 50)!important;
  border-color:rgb(209, 46, 46)!important;
  color: #ddd !important;
}

.list-orders{
  border-bottom: 1px solid rgb(221, 220, 220);
  margin-bottom: 5px;
}


.cart_container{
  border: 1px solid rgb(221, 220, 220);
  border-radius: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.cart_container img{
  border-radius: 5px;
  margin-bottom: 10px;
}

.actions-cart-item{
  display:flex;
  align-items:center;
  justify-content: space-evenly;
}

.center p{
  padding: 0px !important;
}

.inputfretecart{
  display:flex;
  align-items:center;
  width: 100%;
}

.inputfretecart p{
  margin-bottom: 5px;
  margin-right: 5px;
  font-weight: bold;
}

.order{
  background-color:#f9f9f9;
  border: 1px solid #999;
}
.order:nth-child(2n) {
  background-color:#f9f9f9;
}

.linhaitem{
  background-color:#f5f5f5;
  padding:10px 0px;
  border-bottom: 1px solid #999;
}

.linhaitem:nth-child(2n) {
  background-color: #fff;
}
.responsefrete{
  display:flex;
}

.resumo{
  display:flex;
  justify-content:center;
  align-items:center;
}

.resumo p {
  font-size: 13px;
}

.dropzone {
  background: #f1f7f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  border: 1px dashed #019db0;
  padding: 8px;
  cursor: pointer;
}


.dropzone p {
  margin-top: 20px;
  align-self: center;
  text-align: center;
}

.thumbs{
  display:flex;
  justify-content: center;
  width: 100%;
}
